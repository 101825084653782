<template>
	<div class="d-flex align-items-center main">
		<form class="form-signin shadow">
			<img class="mb-4" src="../assets/logo5.png" alt="" width="192"
				>
			<h6 class=" mb-3 font-weight-normal">Please Reset your Password</h6>
			<div class="my-1">
				<label for="inputEmail" class="sr-only mb-2">Password</label>
				<input :type="showPassword ? 'text' : 'password'" id="inputEmail" v-model="password" class="form-control form-control-sm" placeholder="Enter Password" required autofocus>
			</div>
			<div class="my-1">
				<label for="inputPassword" class="sr-only mb-2">Confirm Password</label>
				<input :type="showPassword ? 'text' : 'password'" id="inputPassword" v-model="repeat_password" class="form-control form-control-sm" placeholder="Confirm Password" required>
			</div>
			<div class="form-check my-2">
				<input class="form-check-input" v-model="showPassword"  type="checkbox" value="" id="flexCheckDefault">
				<label v-if="!showPassword" class="form-check-label text-start" for="flexCheckDefault">
					Show Password
				</label>
				<label v-else class="form-check-label text-start" for="flexCheckDefault">
					Hide Password
				</label>
			</div>
			<p class="text-danger" style="font-size: 12px;" v-if="err">{{errMsg}}</p>
			<button class="btn  btn-primary btn-sm w-100 btn-block" type="button" @click="resetPassword()">Rest Password</button>
			<p class="mt-3 text-muted"></p>
		</form>
	</div>


</template>
<script>
import axios from 'axios';

export default {
	data() {
		return {
			err : false,
			errMsg:'',
			showPassword:false,
			password:'',
			repeat_password:'',
			token:null
		}
	},
	created(){
		this.token = this.$route.params.token
	},
	methods:{
		resetPassword(){
			axios.post('https://aghub.miphost.com/api/broadcast/reset_password/' + this.token, {
              password:this.password,
              repeat_password:this.repeat_password
            })
            .then(response =>  {
				this.$router.push({name : 'services'})
            }).catch(error => {
				this.err = true
				this.errMsg = error.response.data
				setTimeout(() => {
					this.err = false
					this.errMsg = ''	
				}, 5000);
            })
		}
	}
}
</script>
<style scoped>
.main{
	height: 100vh;
}

body {
	display: -ms-flexbox;
	display: -webkit-box;
	display: flex;
	-ms-flex-align: center;
	-ms-flex-pack: center;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: center;
	justify-content: center;
	padding-top: 40px;
	padding-bottom: 40px;
	background-color: #f5f5f5;
}

.form-signin {
	width: 100%;
	max-width: 430px;
	padding: 15px;	
	margin: 0 auto;
}

.btn{
  background-color: #e6b600 !important;
  transition: background-color 0.3s cubic-bezier(0.47, 0, 0.745, 0.715); 
  border: none;
}

.btn:hover{
  background-color: #306102 !important;
}

.form-signin .checkbox {
	font-weight: 400;
}

.form-signin .form-control {
	position: relative;
	box-sizing: border-box;
	height: auto;
	padding: 5px;
	font-size: 13px;
	background-color: #cfcfcf !important;
  	border: none;
}

.form-control:focus{
	box-shadow: 0 0 0 0.2rem rgba(233, 246, 0, 0.33);
}

.form-signin .form-control:focus {
	z-index: 2;
}

.form-signin input[type="text"] {
	margin-bottom: 10px;
}

.form-signin input[type="password"] {
	margin-bottom: 10px;
}
.form-check-label{
	width: 100%;
	text-align: left;
	font-size: 12px;
}
</style>